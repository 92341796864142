/**********************************************************************
*
*   Component generated by Quest
*
*   WARNING: By editing this component by hand, you will lose the ability to regenerate the code without conflicts. 
*   To preseve that abilty, always export from Quest to regenerate this file.
*   To setup props, bindings and actions, use the Quest editor
*   Code Logic goes in the hook associated with this component
*
*   For help and further details refer to: https://www.quest.ai/docs
*
*
**********************************************************************/

import { Avatar } from '@mui/material';
import MoonbaseLogoImage from './assets/images/MoonbaseLogo.png';
import MoonbaseLogoMImage from './assets/images/MoonbaseLogo_M.png';
import Avatar1Image from './assets/images/Avatar.png';
import { styled } from '@mui/material/styles';
import SidebarRow from './SidebarRow';
import useSidebar from './useSidebar';
 
const CollapsableOpen = styled("div", {
    shouldForwardProp: prop => !["data"].includes(prop)
  })(({ data }) =>({  
  backgroundColor: `rgba(0, 0, 0, 1)`,  
  display: `flex`,  
  flexDirection: `column`,  
  height: "100vh",  
  width: !data.isHover ? `90px` : `290px`,  
  justifyContent: `space-between`,  
  alignItems: `flex-start`,  
  padding: `0px`,  
  boxSizing: `border-box`,  
  overflow: "hidden",  
}));
  
const Top = styled("div")({  
  display: `flex`,  
  flexDirection: `column`,  
  justifyContent: `flex-start`,  
  alignItems: `flex-start`,  
  gap: `56px`,  
  padding: `20px 0px 0px 0px`,  
  boxSizing: `border-box`,  
  alignSelf: `stretch`,  
});
  
const Logo = styled("div", {
    shouldForwardProp: prop => !["data"].includes(prop)
  })(({ data }) =>({  
  display: `flex`,  
  flexDirection: `row`,  
  justifyContent: !data.isHover ? `flex-end` : `flex-start`,  
  alignItems: `center`,  
  padding: `16px 0px 0px 40px`,  
  boxSizing: `border-box`,  
  width: !data.isHover ? `51px` : "unset",  
  overflow: !data.isHover ? `hidden` : "unset",  
}));
  
const MoonbaseLogo = styled("img")({  
  height: `11.26px`,  
  width: `100px`,  
});
  
const MoonbaseLogoM = styled("img")({  
  height: `11px`,  
  width: `9px`,  
});
  
const List = styled("div")({  
  display: `flex`,  
  flexDirection: `column`,  
  justifyContent: `flex-start`,  
  alignItems: `flex-start`,  
  gap: `6px`,  
  padding: `0px 0px 0px 22px`,  
  boxSizing: `border-box`,  
  alignSelf: `stretch`,  
});
  
const SidebarRow1 = styled(SidebarRow)(({ theme }) =>({  
  alignSelf: `stretch`,  
  height: `40px`,  
}));
  
const Account1 = styled("div")({  
  backgroundColor: `rgba(43, 43, 43, 1)`,  
  display: `flex`,  
  flexDirection: `column`,  
  justifyContent: `center`,  
  alignItems: `flex-start`,  
  gap: `8px`,  
  padding: `0px`,  
  boxSizing: `border-box`,  
  alignSelf: `stretch`,  
  height: `60px`,  
});
  
const AvatarRow = styled("div")({  
  display: `flex`,  
  flexDirection: `row`,  
  justifyContent: `flex-start`,  
  alignItems: `center`,  
  gap: `8px`,  
  padding: `0px 30px`,  
  boxSizing: `border-box`,  
  alignSelf: `stretch`,  
});
  
const Avatar1 = styled(Avatar)({  
  width: `32px`,  
  height: `32px`,  
});
  
const FullName = styled("div")({  
  textAlign: `left`,  
  whiteSpace: `pre-wrap`,  
  color: `rgba(255, 255, 255, 1)`,  
  fontStyle: `normal`,  
  fontFamily: `Inter`,  
  fontWeight: `700`,  
  fontSize: `16px`,  
  letterSpacing: `0.64px`,  
  textDecoration: `none`,  
  textTransform: `none`,  
  flexGrow: `1`,  
});
 
function Sidebar() {
  const {data, fns} = useSidebar();
  return (
    <CollapsableOpen onMouseEnter={fns.handleMouseOver} onMouseLeave={fns.handleMouseOut} data={data} >
       <Top >
         <Logo data={data} >
           {!(!data.isHover) ?
               <MoonbaseLogo  src={MoonbaseLogoImage} alt={"MoonbaseLogo"}/>
           :null}
           {(!data.isHover) ?
               <MoonbaseLogoM  src={MoonbaseLogoMImage} alt={"MoonbaseLogo_M"}/>
           :null}
         </Logo>
         <List >
           {data.sidebarItems && data.sidebarItems.map((sidebarItem, index) => {
             return (
               <SidebarRow1  key={index}  sidebarItem={sidebarItem} isSelected={index === data.selectedIndex} handleClick={() => fns.handleClick(index)} sidebarHover={data.isHover}/>
             )
           })}
         </List>
       </Top>
       <Account1 >
         <AvatarRow >
           <Avatar1 variant="circular" src={Avatar1Image} alt={"Avatar"}   />
           {!(!data.isHover) ?
               <FullName >
               {`Ellen Cho`}
                 </FullName>
           :null}
         </AvatarRow>
       </Account1>
     </CollapsableOpen>
   );
}

export default Sidebar;
