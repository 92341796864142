/**********************************************************************
*
*   Component hook generated by Quest
*
*   Code Logic for the component goes in this hook
*   To setup bindings that use the data here or call the functions here, use the Quest editor   
*
*   For help and further details refer to: https://www.quest.ai/docs
*
*
**********************************************************************/
import useResponsiveSize from "../../useResponsiveSize";

const useQ404 = () => {
  let size = useResponsiveSize();
  let data = { size };

  let fns = {};
  return { data, fns };
}

export default useQ404;