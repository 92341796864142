/**********************************************************************
*
*   Component generated by Quest
*
*   WARNING: By editing this component by hand, you will lose the ability to regenerate the code without conflicts. 
*   To preseve that abilty, always export from Quest to regenerate this file.
*   To setup props, bindings and actions, use the Quest editor
*   Code Logic goes in the hook associated with this component
*
*   For help and further details refer to: https://www.quest.ai/docs
*
*
**********************************************************************/

import { Dialog } from '@mui/material';
import { styled } from '@mui/material/styles';
import Breadcrumbs from './Breadcrumbs';
import ProductCard from './ProductCard';
import PaymentCard from './PaymentCard';
import useProductsPage from './useProductsPage';
 
const ScreenDesktop = styled("div", {
    shouldForwardProp: prop => !["data"].includes(prop)
  })(({ data }) =>({  
  backgroundColor: `rgba(255, 255, 255, 1)`,  
  display: `flex`,  
  flexDirection: `column`,  
  height: data.size=== "mobile" ? "100vh" : `1018px`,  
  width: "100vw",  
  justifyContent: `flex-start`,  
  alignItems: data.size=== "mobile" ? `center` : `flex-start`,  
  gap: `10px`,  
  padding: data.size=== "mobile" ? `10px` : `0px`,  
  boxSizing: `border-box`,  
  overflow: data.size=== "mobile" ? "scroll" : "unset",  
}));
  
const Page = styled("div", {
    shouldForwardProp: prop => !["data"].includes(prop)
  })(({ data }) =>({  
  display: `flex`,  
  flexDirection: `row`,  
  justifyContent: data.size=== "mobile" ? `center` : `flex-start`,  
  alignItems: `flex-start`,  
  gap: `10px`,  
  padding: data.size=== "mobile" ? `10px` : `0px`,  
  boxSizing: `border-box`,  
  alignSelf: `stretch`,  
  flexGrow: data.size=== "mobile" ? "unset" : `1`,  
}));
  
const Content = styled("div", {
    shouldForwardProp: prop => !["data"].includes(prop)
  })(({ data }) =>({  
  display: `flex`,  
  flexDirection: `column`,  
  justifyContent: `flex-start`,  
  alignItems: data.size=== "mobile" ? `center` : `flex-start`,  
  padding: data.size=== "mobile" ? `20px 0px 0px 0px` : `120px 40px 0px 40px`,  
  boxSizing: `border-box`,  
  alignSelf: `stretch`,  
  flexGrow: `1`,  
}));
  
const Breadcrumbs1 = styled(Breadcrumbs)(({ theme }) =>({  
  alignSelf: `stretch`,  
}));
  
const Items = styled("div", {
    shouldForwardProp: prop => !["data"].includes(prop)
  })(({ data }) =>({  
  display: `flex`,  
  flexDirection: data.size=== "mobile" ? `column` : `row`,  
  justifyContent: `flex-start`,  
  alignItems: data.size=== "mobile" ? `center` : `flex-start`,  
  gap: `30px`,  
  padding: data.size=== "mobile" ? `0px 10px 60px 10px` : `0px`,  
  boxSizing: `border-box`,  
  alignSelf: `stretch`,  
}));
  
const ProductCard1 = styled(ProductCard, {
    shouldForwardProp: prop => !["data"].includes(prop)
  })(({ theme, data }) =>({  
  flexGrow: data.size=== "mobile" ? "unset" : `1`,  
  alignSelf: data.size=== "mobile" ? `stretch` : "unset",  
}));
  
const PaymentCard1 = styled(PaymentCard, {
    shouldForwardProp: prop => !["data"].includes(prop)
  })(({ theme, data }) =>({  
  height: data.size=== "mobile" ? "unset" : `574px`,  
  width: data.size=== "mobile" ? `360px` : `728px`,  
}));
 
function ProductsPage() {
  const {data, fns} = useProductsPage();
  return (
    <ScreenDesktop data={data} >
       <Page data={data} >
         <Content data={data} >
           <Breadcrumbs1   />
           <Items data={data} >
             {data.products && data.products.map((product, index) => {
               return (
                 <ProductCard1  key={index} data={data}  product={product} buyClicked={() => fns.buyClicked(product)}/>
               )
             })}
           </Items>
         </Content>
       </Page>
       <Dialog open={data.showPayment} onClose={fns.closePaymentDialog} maxWidth={"1000px"}>
         <PaymentCard1  data={data}   product={data.selectedProduct} onClose={fns.closePaymentDialog}/>
       </Dialog>
     </ScreenDesktop>
   );
}

export default ProductsPage;
