/**********************************************************************
*
*   Component generated by Quest
*
*   WARNING: By editing this component by hand, you will lose the ability to regenerate the code without conflicts. 
*   To preseve that abilty, always export from Quest to regenerate this file.
*   To setup props, bindings and actions, use the Quest editor
*   Code Logic goes in the hook associated with this component
*
*   For help and further details refer to: https://www.quest.ai/docs
*
*
**********************************************************************/

import { Button, SvgIcon } from '@mui/material';
import { ReactComponent as ArrowForwardFilled1 } from './assets/images/ArrowForwardFilled.svg';
import { styled } from '@mui/material/styles';
import FeedbackBar from './FeedbackBar';
import useFeedbackBarPage from './useFeedbackBarPage';
 
const TypeQuestSidebarOpen = styled("div")({  
  backgroundColor: `rgba(255, 255, 255, 1)`,  
  display: `flex`,  
  flexDirection: `column`,  
  height: "100vh",  
  width: "100vw",  
  justifyContent: `flex-start`,  
  alignItems: `flex-start`,  
  padding: `0px`,  
  boxSizing: `border-box`,  
});
  
const Page = styled("div")({  
  display: `flex`,  
  flexDirection: `column`,  
  justifyContent: `flex-start`,  
  alignItems: `flex-start`,  
  padding: `0px`,  
  boxSizing: `border-box`,  
  alignSelf: `stretch`,  
  height: `1079px`,  
});
  
const FeedbackBar1 = styled(FeedbackBar)(({ theme }) =>({  
  alignSelf: `stretch`,  
}));
  
const Content = styled("div")({  
  display: `flex`,  
  flexDirection: `column`,  
  justifyContent: `flex-start`,  
  alignItems: `flex-start`,  
  padding: `60px 0px 0px 0px`,  
  boxSizing: `border-box`,  
  alignSelf: `stretch`,  
  flexGrow: `1`,  
});
  
const Sec1 = styled("div")({  
  display: `flex`,  
  flexDirection: `column`,  
  justifyContent: `flex-start`,  
  alignItems: `flex-start`,  
  padding: `22px 60px 30px 60px`,  
  boxSizing: `border-box`,  
});
  
const HeroContent = styled("div")({  
  display: `flex`,  
  flexDirection: `row`,  
  justifyContent: `flex-start`,  
  alignItems: `center`,  
  gap: `117px`,  
  padding: `0px 0px 20px 0px`,  
  boxSizing: `border-box`,  
});
  
const WelcomeRow = styled("div")({  
  display: `flex`,  
  flexDirection: `column`,  
  justifyContent: `flex-start`,  
  alignItems: `flex-start`,  
  gap: `40px`,  
  padding: `0px`,  
  boxSizing: `border-box`,  
});
  
const Features = styled("div")({  
  display: `flex`,  
  flexDirection: `column`,  
  justifyContent: `flex-start`,  
  alignItems: `flex-start`,  
  gap: `8px`,  
  padding: `0px`,  
  boxSizing: `border-box`,  
  width: `814px`,  
});
  
const Feature = styled("div")({  
  textAlign: `left`,  
  whiteSpace: `pre-wrap`,  
  color: `rgba(0, 0, 0, 1)`,  
  fontStyle: `normal`,  
  fontFamily: `Inter`,  
  fontWeight: `700`,  
  fontSize: `16px`,  
  letterSpacing: `0.09090909361839294px`,  
  textDecoration: `none`,  
  lineHeight: `26px`,  
  textTransform: `none`,  
  alignSelf: `stretch`,  
});
  
const Feature1 = styled("div")({  
  textAlign: `left`,  
  whiteSpace: `pre-wrap`,  
  color: `rgba(86, 86, 86, 1)`,  
  fontStyle: `normal`,  
  fontFamily: `Inter`,  
  fontWeight: `400`,  
  fontSize: `16px`,  
  letterSpacing: `0.09090909361839294px`,  
  textDecoration: `none`,  
  lineHeight: `26px`,  
  textTransform: `none`,  
  alignSelf: `stretch`,  
});
  
const Divider = styled("div")({  
  display: `flex`,  
  flexDirection: `row`,  
  justifyContent: `center`,  
  alignItems: `center`,  
  padding: `6px 0px`,  
  boxSizing: `border-box`,  
  alignSelf: `stretch`,  
  overflow: `hidden`,  
});
  
const Divider1 = styled("div")({  
  display: `flex`,  
  flexDirection: `row`,  
  justifyContent: `flex-start`,  
  alignItems: `center`,  
  gap: `6px`,  
  padding: `0px`,  
  boxSizing: `border-box`,  
  flexGrow: `1`,  
});
  
const Divider2 = styled("div")({  
  backgroundColor: `rgba(86, 86, 86, 1)`,  
  flexGrow: `1`,  
  height: `1px`,  
});
  
const Feature2 = styled("div")({  
  textAlign: `left`,  
  whiteSpace: `pre-wrap`,  
  color: `rgba(86, 86, 86, 1)`,  
  fontStyle: `normal`,  
  fontFamily: `Inter`,  
  fontWeight: `400`,  
  fontSize: `16px`,  
  letterSpacing: `0.09090909361839294px`,  
  textDecoration: `none`,  
  lineHeight: `26px`,  
  textTransform: `none`,  
  alignSelf: `stretch`,  
});
  
const Divider3 = styled("div")({  
  display: `flex`,  
  flexDirection: `row`,  
  justifyContent: `center`,  
  alignItems: `center`,  
  padding: `6px 0px`,  
  boxSizing: `border-box`,  
  alignSelf: `stretch`,  
  overflow: `hidden`,  
});
  
const Divider4 = styled("div")({  
  display: `flex`,  
  flexDirection: `row`,  
  justifyContent: `flex-start`,  
  alignItems: `center`,  
  gap: `6px`,  
  padding: `0px`,  
  boxSizing: `border-box`,  
  flexGrow: `1`,  
});
  
const Divider5 = styled("div")({  
  backgroundColor: `rgba(86, 86, 86, 1)`,  
  flexGrow: `1`,  
  height: `1px`,  
});
  
const Feature3 = styled("div")({  
  textAlign: `left`,  
  whiteSpace: `pre-wrap`,  
  color: `rgba(86, 86, 86, 1)`,  
  fontStyle: `normal`,  
  fontFamily: `Inter`,  
  fontWeight: `400`,  
  fontSize: `16px`,  
  letterSpacing: `0.09090909361839294px`,  
  textDecoration: `none`,  
  lineHeight: `26px`,  
  textTransform: `none`,  
  alignSelf: `stretch`,  
});
  
const Divider6 = styled("div")({  
  display: `flex`,  
  flexDirection: `row`,  
  justifyContent: `center`,  
  alignItems: `center`,  
  padding: `6px 0px`,  
  boxSizing: `border-box`,  
  alignSelf: `stretch`,  
  overflow: `hidden`,  
});
  
const Divider7 = styled("div")({  
  display: `flex`,  
  flexDirection: `row`,  
  justifyContent: `flex-start`,  
  alignItems: `center`,  
  gap: `6px`,  
  flexGrow: `1`,  
});
  
const Next = styled("div")({  
  display: `flex`,  
  flexDirection: `column`,  
  justifyContent: `flex-start`,  
  alignItems: `flex-start`,  
  gap: `6px`,  
  padding: `0px`,  
  boxSizing: `border-box`,  
});
 
function FeedbackBarPage() {
  const {fns} = useFeedbackBarPage();
  return (
    <TypeQuestSidebarOpen >
       <Page >
         <FeedbackBar1   />
         <Content >
           <Sec1 >
             <HeroContent >
               <WelcomeRow >
                 <Features >
                   <Feature >
                     {`This interactive Feedback component:`}
                       </Feature>
                   <Feature1 >
                     {`Uses list binding to dynamically render a list of reactions`}
                       </Feature1>
                   <Divider >
                     <Divider1 >
                       <Divider2 >
                       </Divider2>
                     </Divider1>
                   </Divider>
                   <Feature2 >
                     {`Uses nested feedback item with variants to show different states on hover`}
                       </Feature2>
                   <Divider3 >
                     <Divider4 >
                       <Divider5 >
                       </Divider5>
                     </Divider4>
                   </Divider3>
                   <Feature3 >
                     {`Is fully responsive to the full width of the parent`}
                       </Feature3>
                   <Divider6 >
                     <Divider7 >
                     </Divider7>
                   </Divider6>
                 </Features>
                 <Next >
                   <Button variant="contained" size="large" color="primary"  onClick={() => fns.navigate("/moonbase/dashboard")} endIcon={ <SvgIcon sx={{width: "18px", height: "22px"}} component = { ArrowForwardFilled1 } />}  > See Full Dashboard </Button>
                 </Next>
               </WelcomeRow>
             </HeroContent>
           </Sec1>
         </Content>
       </Page>
     </TypeQuestSidebarOpen>
   );
}

export default FeedbackBarPage;
