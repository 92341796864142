/**********************************************************************
*
*   Component generated by Quest
*
*   WARNING: By editing this component by hand, you will lose the ability to regenerate the code without conflicts. 
*   To preseve that abilty, always export from Quest to regenerate this file.
*   To setup props, bindings and actions, use the Quest editor
*   Code Logic goes in the hook associated with this component
*
*   For help and further details refer to: https://www.quest.ai/docs
*
*
**********************************************************************/

import { Button, SvgIcon } from '@mui/material';
import Q4041Image from './assets/images/_404.png';
import { ReactComponent as ArrowForwardFilled1 } from './assets/images/ArrowForwardFilled.svg';
import { styled } from '@mui/material/styles';
import HeroImage from './assets/images/Hero.png';
import useQ404 from './useQ404';
 
const ScreenDesktop = styled("div", {
    shouldForwardProp: prop => !["data"].includes(prop)
  })(({ data }) =>({  
  backgroundColor: `rgba(255, 255, 255, 1)`,  
  display: `flex`,  
  flexDirection: data.size === "tablet" ? `row` : data.size === "mobile" ? `row` : `column`,  
  height: "100vh",  
  width: "100vw",  
  justifyContent: `flex-start`,  
  alignItems: `flex-start`,  
  gap: `10px`,  
  padding: `0px`,  
  boxSizing: `border-box`,  
  overflow: `hidden`,  
}));
  
const Hero = styled("div", {
    shouldForwardProp: prop => !["data"].includes(prop)
  })(({ data }) =>({  
  backgroundImage: `url(${HeroImage})`,  
  backgroundPosition: `center`,  
  backgroundSize: `cover`,  
  backgroundRepeat: `no-repeat`,  
  display: `flex`,  
  flexDirection: `column`,  
  justifyContent: data.size === "mobile" ? `center` : `flex-start`,  
  alignItems: data.size === "mobile" ? `center` : `flex-start`,  
  padding: `0px`,  
  boxSizing: `border-box`,  
  alignSelf: `stretch`,  
  flexGrow: `1`,  
  overflow: `hidden`,  
}));
  
const FillHero = styled("div", {
    shouldForwardProp: prop => !["data"].includes(prop)
  })(({ data }) =>({  
  display: `flex`,  
  flexDirection: `column`,  
  justifyContent: `flex-start`,  
  alignItems: `center`,  
  gap: data.size === "tablet" ? `20px` : `30px`,  
  padding: data.size === "tablet" ? `50px 0px 0px 0px` : data.size === "mobile" ? `60px 0px 0px 0px` : `40px 0px 0px 0px`,  
  boxSizing: `border-box`,  
  alignSelf: `stretch`,  
  flexGrow: `1`,  
}));
  
const Content = styled("div", {
    shouldForwardProp: prop => !["data"].includes(prop)
  })(({ data }) =>({  
  display: `flex`,  
  flexDirection: `column`,  
  justifyContent: `flex-start`,  
  alignItems: `center`,  
  padding: `0px`,  
  boxSizing: `border-box`,  
  gap: data.size === "mobile" ? `20px` : "unset",  
  alignSelf: data.size === "mobile" ? `stretch` : "unset",  
}));
  
const Right = styled("div", {
    shouldForwardProp: prop => !["data"].includes(prop)
  })(({ data }) =>({  
  display: `flex`,  
  flexDirection: `row`,  
  justifyContent: data.size === "mobile" ? `center` : `flex-start`,  
  alignItems: `center`,  
  gap: `20px`,  
  padding: `0px`,  
  boxSizing: `border-box`,  
  height: data.size === "tablet" ? `239px` : data.size === "mobile" ? `190px` : `293px`,  
  width: data.size === "mobile" ? `320px` : "unset",  
}));
  
const Q4041 = styled("img", {
    shouldForwardProp: prop => !["data"].includes(prop)
  })(({ data }) =>({  
  height: data.size === "tablet" ? `141px` : data.size === "mobile" ? `100px` : `201.5px`,  
  width: data.size === "tablet" ? `362px` : data.size === "mobile" ? `258px` : `518.5px`,  
}));
  
const Left = styled("div")({  
  display: `flex`,  
  flexDirection: `column`,  
  justifyContent: `flex-start`,  
  alignItems: `center`,  
  gap: `48px`,  
  padding: `0px`,  
  boxSizing: `border-box`,  
});
  
const Frame1 = styled("div")({  
  display: `flex`,  
  flexDirection: `column`,  
  justifyContent: `flex-start`,  
  alignItems: `center`,  
  gap: `10px`,  
  padding: `0px`,  
  boxSizing: `border-box`,  
});
  
const PageNotFound = styled("div", {
    shouldForwardProp: prop => !["data"].includes(prop)
  })(({ data }) =>({  
  textAlign: data.size === "tablet" ? `left` : data.size === "mobile" ? `left` : `center`,  
  whiteSpace: `pre-wrap`,  
  color: `rgba(0, 0, 0, 1)`,  
  fontStyle: `normal`,  
  fontFamily: `Roboto`,  
  fontWeight: `400`,  
  fontSize: `34px`,  
  letterSpacing: `0.25px`,  
  textDecoration: `none`,  
  lineHeight: `123.50000143051147%`,  
  textTransform: `none`,  
}));
  
const LooksLikeYouMightBeL = styled("div", {
    shouldForwardProp: prop => !["data"].includes(prop)
  })(({ data }) =>({  
  textAlign: data.size === "tablet" ? `left` : data.size === "mobile" ? `left` : `center`,  
  whiteSpace: `pre-wrap`,  
  color: `rgba(0, 0, 0, 1)`,  
  fontStyle: `normal`,  
  fontFamily: `Heebo`,  
  fontWeight: `400`,  
  fontSize: `20px`,  
  letterSpacing: `0px`,  
  textDecoration: `none`,  
  textTransform: `none`,  
}));
 
function Q404() {
  const {data} = useQ404();
  return (
    <ScreenDesktop data={data} >
       <Hero data={data} >
         <FillHero data={data} >
           <Content data={data} >
             <Right data={data} >
               <Q4041 data={data}  src={Q4041Image} alt={"404"}/>
             </Right>
             <Left >
               <Frame1 >
                 <PageNotFound data={data} >
                   {`Page not found`}
                     </PageNotFound>
                 <LooksLikeYouMightBeL data={data} >
                   {`Looks like you might be lost...`}
                     </LooksLikeYouMightBeL>
               </Frame1>
               {false ?
                   <Button variant="text" size="large" color="primary"   endIcon={ <SvgIcon sx={{width: "18px", height: "22px"}} component = { ArrowForwardFilled1 } />}  > Go Back Home </Button>
               :null}
             </Left>
           </Content>
         </FillHero>
       </Hero>
     </ScreenDesktop>
   );
}

export default Q404;
