/**********************************************************************
*
*   Component generated by Quest
*
*   WARNING: By editing this component by hand, you will lose the ability to regenerate the code without conflicts. 
*   To preseve that abilty, always export from Quest to regenerate this file.
*   To setup props, bindings and actions, use the Quest editor
*   Code Logic goes in the hook associated with this component
*
*   For help and further details refer to: https://www.quest.ai/docs
*
*
**********************************************************************/

import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import ImageImage from './assets/images/Image_4.png';
import Image1Image from './assets/images/Image_3.png';
import Image2Image from './assets/images/Image_2.png';
import Image3Image from './assets/images/Image_1.png';
import Sidebar from './Sidebar';
import NavBar from './NavBar';
import FeedbackBar from './FeedbackBar';
import Upcoming from './Upcoming';
import Notifications from './Notifications';

const TypeQuestSidebarOpen = styled("div")({
  backgroundColor: `rgba(255, 255, 255, 1)`,
  display: `flex`,
  flexDirection: `column`,
  width: "100vw",
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
});

const Page = styled("div")({
  display: `flex`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  alignSelf: `stretch`,
  height: "100%",
});

const Sidebar1 = styled(Sidebar)(({ theme }) => ({
  alignSelf: `stretch`,
  width: `290px`,
}));

const Content = styled("div")({
  display: `flex`,
  flexDirection: `column`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  alignSelf: `stretch`,
  flexGrow: `1`,
  height: "100vh",
  width: "calc(100vw - 290px)",
  overflow: "auto",
});

const NavBar1 = styled(NavBar)(({ theme }) => ({
  alignSelf: `stretch`,
  height: `80px`,
}));

const Sec1 = styled("div")({
  display: `flex`,
  flexDirection: `column`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `22px 60px 30px 60px`,
  boxSizing: `border-box`,
  alignSelf: `stretch`,
});

const HeroContent = styled("div")({
  display: `flex`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `center`,
  gap: `117px`,
  padding: `0px 0px 20px 0px`,
  boxSizing: `border-box`,
  alignSelf: `stretch`,
});

const WelcomeRow = styled("div")({
  display: `flex`,
  flexDirection: `column`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  gap: `10px`,
  padding: `0px`,
  boxSizing: `border-box`,
  flexGrow: `1`,
});

const WelcomeText = styled("div")({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  color: `rgba(0, 0, 0, 1)`,
  fontStyle: `normal`,
  fontFamily: `Inter`,
  fontWeight: `700`,
  fontSize: `28px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  textTransform: `none`,
});

const FeedbackBar1 = styled(FeedbackBar)(({ theme }) => ({
  alignSelf: `stretch`,
}));

const Sec2 = styled("div")({
  display: `flex`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px 61px`,
  boxSizing: `border-box`,
  alignSelf: `stretch`,
});

const Padding = styled("div")({
  display: `flex`,
  flexDirection: `column`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  gap: `22px`,
  width: `252px`,
});

const Sec3 = styled("div")({
  display: `flex`,
  flexDirection: `column`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  gap: `20px`,
  padding: `40px 61px 0px 61px`,
  boxSizing: `border-box`,
  alignSelf: `stretch`,
});

const UpcomingEvents = styled("div")({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  color: `rgba(0, 0, 0, 1)`,
  fontStyle: `normal`,
  fontFamily: `Inter`,
  fontWeight: `700`,
  fontSize: `22px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  textTransform: `none`,
});

const Items = styled("div")({
  display: `flex`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  gap: `30px`,
  padding: `0px`,
  boxSizing: `border-box`,
  alignSelf: `stretch`,
});

const EventCard = styled("div")({
  backgroundColor: `rgba(255, 255, 255, 1)`,
  boxShadow: `0px 4px 6px rgba(3, 0, 161, 0.2)`,
  borderRadius: `8px`,
  display: `flex`,
  flexDirection: `column`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  gap: `14px`,
  padding: `0px`,
  boxSizing: `border-box`,
  flexGrow: `1`,
  overflow: `hidden`,
});

const Image = styled("div")({
  backgroundImage: `url(${ImageImage})`,
  backgroundPosition: `center`,
  backgroundSize: `cover`,
  backgroundRepeat: `no-repeat`,
  alignSelf: `stretch`,
  height: `171px`,
  overflow: `hidden`,
});

const TextButton = styled("div")({
  display: `flex`,
  flexDirection: `column`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  gap: `14px`,
  padding: `0px 40px 30px 28px`,
  boxSizing: `border-box`,
  alignSelf: `stretch`,
});

const Details = styled("div")({
  display: `flex`,
  flexDirection: `column`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  gap: `2px`,
  padding: `0px`,
  boxSizing: `border-box`,
  alignSelf: `stretch`,
});

const ItemName = styled("div")(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  color: `rgba(0, 0, 0, 1)`,
  fontStyle: `normal`,
  fontFamily: `Heebo`,
  fontWeight: `400`,
  fontSize: `18px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  textTransform: `none`,
  alignSelf: `stretch`,
}));

const ItemPrice = styled("div")(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  color: `rgba(0, 0, 0, 1)`,
  fontStyle: `normal`,
  fontFamily: `Heebo`,
  fontWeight: `700`,
  fontSize: `14px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  textTransform: `none`,
}));

const EventCard1 = styled("div")({
  backgroundColor: `rgba(255, 255, 255, 1)`,
  boxShadow: `0px 4px 6px rgba(3, 0, 161, 0.2)`,
  borderRadius: `8px`,
  display: `flex`,
  flexDirection: `column`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  gap: `14px`,
  padding: `0px`,
  boxSizing: `border-box`,
  flexGrow: `1`,
  overflow: `hidden`,
});

const Image1 = styled("div")({
  backgroundImage: `url(${Image1Image})`,
  backgroundPosition: `center`,
  backgroundSize: `cover`,
  backgroundRepeat: `no-repeat`,
  alignSelf: `stretch`,
  height: `171px`,
  overflow: `hidden`,
});

const TextButton1 = styled("div")({
  display: `flex`,
  flexDirection: `column`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  gap: `14px`,
  padding: `0px 40px 30px 28px`,
  boxSizing: `border-box`,
  alignSelf: `stretch`,
});

const Details1 = styled("div")({
  display: `flex`,
  flexDirection: `column`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  gap: `2px`,
  padding: `0px`,
  boxSizing: `border-box`,
  alignSelf: `stretch`,
});

const ItemName1 = styled("div")(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  color: `rgba(0, 0, 0, 1)`,
  fontStyle: `normal`,
  fontFamily: `Heebo`,
  fontWeight: `400`,
  fontSize: `18px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  textTransform: `none`,
  alignSelf: `stretch`,
}));

const ItemPrice1 = styled("div")(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  color: `rgba(0, 0, 0, 1)`,
  fontStyle: `normal`,
  fontFamily: `Heebo`,
  fontWeight: `700`,
  fontSize: `14px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  textTransform: `none`,
}));

const EventCard2 = styled("div")({
  backgroundColor: `rgba(255, 255, 255, 1)`,
  boxShadow: `0px 4px 6px rgba(3, 0, 161, 0.2)`,
  borderRadius: `8px`,
  display: `flex`,
  flexDirection: `column`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  gap: `14px`,
  padding: `0px`,
  boxSizing: `border-box`,
  flexGrow: `1`,
  overflow: `hidden`,
});

const Image2 = styled("div")({
  backgroundImage: `url(${Image2Image})`,
  backgroundPosition: `center`,
  backgroundSize: `cover`,
  backgroundRepeat: `no-repeat`,
  alignSelf: `stretch`,
  height: `171px`,
  overflow: `hidden`,
});

const TextButton2 = styled("div")({
  display: `flex`,
  flexDirection: `column`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  gap: `14px`,
  padding: `0px 40px 30px 28px`,
  boxSizing: `border-box`,
  alignSelf: `stretch`,
});

const Details2 = styled("div")({
  display: `flex`,
  flexDirection: `column`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  gap: `2px`,
  padding: `0px`,
  boxSizing: `border-box`,
  alignSelf: `stretch`,
});

const ItemName2 = styled("div")(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  color: `rgba(0, 0, 0, 1)`,
  fontStyle: `normal`,
  fontFamily: `Heebo`,
  fontWeight: `400`,
  fontSize: `18px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  textTransform: `none`,
  alignSelf: `stretch`,
}));

const ItemPrice2 = styled("div")(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  color: `rgba(0, 0, 0, 1)`,
  fontStyle: `normal`,
  fontFamily: `Heebo`,
  fontWeight: `700`,
  fontSize: `14px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  textTransform: `none`,
}));

const EventCard3 = styled("div")({
  backgroundColor: `rgba(255, 255, 255, 1)`,
  boxShadow: `0px 4px 6px rgba(3, 0, 161, 0.2)`,
  borderRadius: `8px`,
  display: `flex`,
  flexDirection: `column`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  gap: `14px`,
  padding: `0px`,
  boxSizing: `border-box`,
  flexGrow: `1`,
  overflow: `hidden`,
});

const Image3 = styled("div")({
  backgroundImage: `url(${Image3Image})`,
  backgroundPosition: `center`,
  backgroundSize: `cover`,
  backgroundRepeat: `no-repeat`,
  alignSelf: `stretch`,
  height: `171px`,
  overflow: `hidden`,
});

const TextButton3 = styled("div")({
  display: `flex`,
  flexDirection: `column`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  gap: `14px`,
  padding: `0px 40px 30px 28px`,
  boxSizing: `border-box`,
  alignSelf: `stretch`,
});

const Details3 = styled("div")({
  display: `flex`,
  flexDirection: `column`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  gap: `2px`,
  padding: `0px`,
  boxSizing: `border-box`,
  alignSelf: `stretch`,
});

const ItemName3 = styled("div")(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  color: `rgba(0, 0, 0, 1)`,
  fontStyle: `normal`,
  fontFamily: `Heebo`,
  fontWeight: `400`,
  fontSize: `18px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  textTransform: `none`,
  alignSelf: `stretch`,
}));

const ItemPrice3 = styled("div")(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  color: `rgba(0, 0, 0, 1)`,
  fontStyle: `normal`,
  fontFamily: `Heebo`,
  fontWeight: `700`,
  fontSize: `14px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  textTransform: `none`,
}));

const Sec4 = styled("div")({
  display: `flex`,
  flexDirection: `column`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  gap: `20px`,
  alignSelf: `stretch`,
  height: `406px`,
});

function Dashboard() {
  return (
    <TypeQuestSidebarOpen >
      <Page >
        <Sidebar1 />
        <Content >
          <NavBar1 />
          <Sec1 >
            <HeroContent >
              <WelcomeRow >
                <WelcomeText >
                  {`Welcome back to Moon Base, Ellen`}
                </WelcomeText>
              </WelcomeRow>
            </HeroContent>
            <FeedbackBar1 />
          </Sec1>
          <Sec2 >
            <Upcoming />
            <Padding >
            </Padding>
            <Notifications />
          </Sec2>
          <Sec3 >
            <UpcomingEvents >
              {`Upcoming Events`}
            </UpcomingEvents>
            <Items >
              <EventCard >
                <Image >
                </Image>
                <TextButton >
                  <Details >
                    <ItemName >
                      {`Design Conference`}
                    </ItemName>
                    <ItemPrice >
                      {`12PM | Apr 26`}
                    </ItemPrice>
                  </Details>
                  <Button variant="contained" size="large" color="primary"    > See Event </Button>
                </TextButton>
              </EventCard>
              <EventCard1 >
                <Image1 >
                </Image1>
                <TextButton1 >
                  <Details1 >
                    <ItemName1 >
                      {`Keynote`}
                    </ItemName1>
                    <ItemPrice1 >
                      {`10AM | Apr 28`}
                    </ItemPrice1>
                  </Details1>
                  <Button variant="contained" size="large" color="primary"    > See Event </Button>
                </TextButton1>
              </EventCard1>
              <EventCard2 >
                <Image2 >
                </Image2>
                <TextButton2 >
                  <Details2 >
                    <ItemName2 >
                      {`Yoga Retreat`}
                    </ItemName2>
                    <ItemPrice2 >
                      {`2:30PM | Apr 28`}
                    </ItemPrice2>
                  </Details2>
                  <Button variant="contained" size="large" color="primary"    > See Event </Button>
                </TextButton2>
              </EventCard2>
              <EventCard3 >
                <Image3 >
                </Image3>
                <TextButton3 >
                  <Details3 >
                    <ItemName3 >
                      {`Team Offsite`}
                    </ItemName3>
                    <ItemPrice3 >
                      {`4PM | Apr 30`}
                    </ItemPrice3>
                  </Details3>
                  <Button variant="contained" size="large" color="primary"    > See Event </Button>
                </TextButton3>
              </EventCard3>
            </Items>
          </Sec3>
          <Sec4 >
          </Sec4>
        </Content>
      </Page>
    </TypeQuestSidebarOpen>
  );
}

export default Dashboard;
