/**********************************************************************
*
*   Component generated by Quest
*
*   WARNING: By editing this component by hand, you will lose the ability to regenerate the code without conflicts. 
*   To preseve that abilty, always export from Quest to regenerate this file.
*   To setup props, bindings and actions, use the Quest editor
*   Code Logic goes in the hook associated with this component
*
*   For help and further details refer to: https://www.quest.ai/docs
*
*
**********************************************************************/

import HomeImage from './assets/images/Home.png';
import SeparatorImage from './assets/images/Separator_2.png';
import Separator1Image from './assets/images/Separator_1.png';
import Separator2Image from './assets/images/Separator.png';
import { styled } from '@mui/material/styles';
import useBreadcrumbs from './useBreadcrumbs';
 
const ScreenDesktop = styled("div")({  
  display: `flex`,  
  flexDirection: `column`,  
  width: "100vw",  
  justifyContent: `flex-start`,  
  alignItems: `flex-start`,  
  padding: `0px`,  
  boxSizing: `border-box`,  
});
  
const HeroContent = styled("div", {
    shouldForwardProp: prop => !["data"].includes(prop)
  })(({ data }) =>({  
  display: `flex`,  
  flexDirection: `row`,  
  justifyContent: data.size === "mobile" ? `center` : `flex-start`,  
  alignItems: `center`,  
  padding: data.size === "mobile" ? `0px 30px 20px 30px` : `0px 0px 20px 0px`,  
  boxSizing: `border-box`,  
  alignSelf: `stretch`,  
  gap: data.size === "mobile" ? `117px` : "unset",  
}));
  
const Breadcrumb3 = styled("div", {
    shouldForwardProp: prop => !["data"].includes(prop)
  })(({ theme, data }) =>({  
  backgroundColor: theme.palette["Background"]["Paper"],  
  display: `flex`,  
  flexDirection: `row`,  
  justifyContent: data.size === "mobile" ? `space-between` : `flex-start`,  
  alignItems: `center`,  
  gap: data.size === "mobile" ? "unset" : `24px`,  
  padding: `0px`,  
  boxSizing: `border-box`,  
  alignSelf: `stretch`,  
  flexGrow: `1`,  
  overflow: `hidden`,  
}));
  
const Frame45 = styled("div")({  
  display: `flex`,  
  flexDirection: `row`,  
  justifyContent: `flex-start`,  
  alignItems: `flex-start`,  
  gap: `10px`,  
  padding: `0px`,  
  boxSizing: `border-box`,  
});
  
const Home = styled("img", {
    shouldForwardProp: prop => !["data"].includes(prop)
  })(({ data }) =>({  
  height: data.size === "mobile" ? `14px` : `22px`,  
  width: data.size === "mobile" ? `14px` : `22px`,  
}));
  
const Separator = styled("img", {
    shouldForwardProp: prop => !["data"].includes(prop)
  })(({ data }) =>({  
  height: data.size === "mobile" ? `7.5px` : `12.5px`,  
  width: data.size === "mobile" ? `3.6px` : `6px`,  
}));
  
const CompanySwag = styled("div", {
    shouldForwardProp: prop => !["data"].includes(prop)
  })(({ theme, data }) =>({  
  textAlign: `left`,  
  whiteSpace: `pre-wrap`,  
  color: theme.palette["Text"]["Primary"],  
  fontStyle: `normal`,  
  fontFamily: `Heebo`,  
  fontWeight: `400`,  
  fontSize: data.size === "mobile" ? `12px` : `20px`,  
  letterSpacing: `0px`,  
  textDecoration: `none`,  
  textTransform: `none`,  
}));
  
const Separator1 = styled("img", {
    shouldForwardProp: prop => !["data"].includes(prop)
  })(({ data }) =>({  
  height: data.size === "mobile" ? `7.5px` : `12.5px`,  
  width: data.size === "mobile" ? `3.6px` : `6px`,  
}));
  
const Clothing = styled("div", {
    shouldForwardProp: prop => !["data"].includes(prop)
  })(({ theme, data }) =>({  
  textAlign: `left`,  
  whiteSpace: `pre-wrap`,  
  color: theme.palette["Text"]["Primary"],  
  fontStyle: `normal`,  
  fontFamily: `Heebo`,  
  fontWeight: `400`,  
  fontSize: data.size === "mobile" ? `12px` : `20px`,  
  letterSpacing: `0px`,  
  textDecoration: `none`,  
  textTransform: `none`,  
}));
  
const Separator2 = styled("img", {
    shouldForwardProp: prop => !["data"].includes(prop)
  })(({ data }) =>({  
  height: data.size === "mobile" ? `7.5px` : `12.5px`,  
  width: data.size === "mobile" ? `3.6px` : `6px`,  
}));
  
const Latest = styled("div", {
    shouldForwardProp: prop => !["data"].includes(prop)
  })(({ theme, data }) =>({  
  textAlign: `left`,  
  whiteSpace: `pre-wrap`,  
  color: theme.palette["Text"]["Primary"],  
  fontStyle: `normal`,  
  fontFamily: `Heebo`,  
  fontWeight: `400`,  
  fontSize: data.size === "mobile" ? `12px` : `20px`,  
  letterSpacing: `0px`,  
  textDecoration: `none`,  
  textTransform: `none`,  
}));
 
function Breadcrumbs() {
  const {data} = useBreadcrumbs();
  return (
    <ScreenDesktop >
       <HeroContent data={data} >
         <Breadcrumb3 data={data} >
           <Frame45 >
             <Home data={data}  src={HomeImage} alt={"Home"}/>
           </Frame45>
           <Separator data={data}  src={SeparatorImage} alt={"Separator"}/>
           <CompanySwag data={data} >
             {`Company Swag`}
               </CompanySwag>
           <Separator1 data={data}  src={Separator1Image} alt={"Separator"}/>
           <Clothing data={data} >
             {`Clothing`}
               </Clothing>
           <Separator2 data={data}  src={Separator2Image} alt={"Separator"}/>
           <Latest data={data} >
             {`Latest`}
               </Latest>
         </Breadcrumb3>
       </HeroContent>
     </ScreenDesktop>
   );
}

export default Breadcrumbs;
