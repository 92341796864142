/**********************************************************************
*
*   Component hook generated by Quest
*
*   Code Logic for the component goes in this hook
*   To setup bindings that use the data here or call the functions here, use the Quest editor   
*
*   For help and further details refer to: https://www.quest.ai/docs
*
*
**********************************************************************/

import { useNavigate } from "react-router-dom";

const useFeedbackBarPage = () => {
  let data = {};
  const navigate = useNavigate();

  let fns = { navigate };
  return { data, fns };
}

export default useFeedbackBarPage;