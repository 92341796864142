/**********************************************************************
*
*   Component generated by Quest
*
*   WARNING: By editing this component by hand, you will lose the ability to regenerate the code without conflicts. 
*   To preseve that abilty, always export from Quest to regenerate this file.
*   To setup props, bindings and actions, use the Quest editor
*   Code Logic goes in the hook associated with this component
*
*   For help and further details refer to: https://www.quest.ai/docs
*
*
**********************************************************************/

import { SvgIcon } from '@mui/material';
import { styled } from '@mui/material/styles';
import useSidebarRow from './useSidebarRow';
 
const Property1Default = styled("div", {
    shouldForwardProp: prop => !["props", "data"].includes(prop)
  })(({ props, data }) =>({  
  backgroundColor: !props.sidebarHover && props.isSelected ? `rgba(123, 97, 255, 1)` : props.sidebarHover && data.isHover ? `rgba(123, 97, 255, 1)` : props.sidebarHover && props.isSelected ? `rgba(84, 179, 247, 1)` : `rgba(0, 0, 0, 1)`,  
  display: `flex`,  
  flexDirection: `row`,  
  width: `268px`,  
  height: `40px`,  
  justifyContent: `flex-start`,  
  alignItems: `center`,  
  gap: `13px`,  
  padding: `0px 32px 0px 12px`,  
  boxSizing: `border-box`,  
  cursor: `pointer`,  
}));
  
const Icon1 = styled(SvgIcon)(({ theme }) =>({  
  color: theme.palette["Primary"]["Contrast"],  
}));
  
const Text = styled("div", {
    shouldForwardProp: prop => !["props"].includes(prop)
  })(({ theme, props }) =>({  
  textAlign: `left`,  
  whiteSpace: `pre-wrap`,  
  color: !props.sidebarHover && !props.sSelected ? `rgba(255, 255, 255, 1)` : !props.sidebarHover && props.isSelected ? `rgba(255, 255, 255, 1)` : theme.palette["Primary"]["Contrast"],  
  fontStyle: `normal`,  
  fontFamily: `Inter`,  
  fontWeight: `700`,  
  fontSize: `16px`,  
  letterSpacing: `0.64px`,  
  textDecoration: `none`,  
  textTransform: `none`,  
  flexGrow: `1`,  
}));
 
function SidebarRow(props) {
  const {data, fns} = useSidebarRow();
  return (
    <Property1Default onClick={props.handleClick} onMouseOver={fns.handleMouseOver} onMouseOut={fns.handleMouseOut} props={props} data={data} >
       <Icon1    fontSize = { "medium"}  component = { props.sidebarItem.icon}/>
       {!(!props.sidebarHover && !props.sSelected) && !(!props.sidebarHover && props.isSelected) ?
           <Text props={props} >
           {props.sidebarItem.text}
             </Text>
       :null}
     </Property1Default>
   );
}

export default SidebarRow;
